import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from './logo.png';
import goOurHubLogo from './GoOurHub.png'; // new logo

function NavBar({ onSignInClick, onSignUpClick, onSignOutClick, username, onSearch, cartItems }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={goOurHubLogo} className="App-logo1" alt="logo1" />
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`navbar-right ${menuOpen ? 'open' : ''}`}>
        <input
          type="text"
          className="search-bar"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Link to="/">Home</Link>
        <Link to="/about-us">About</Link>
        <Link to="/contact-us">Contact</Link>
        {username ? (
          <button className="nav-button" onClick={onSignOutClick}>Sign Out</button>
        ) : (
          <>
            <button className="nav-button" onClick={onSignInClick}>Sign In</button>
            <button className="nav-button" onClick={onSignUpClick}>Sign Up</button>
          </>
        )}
        <div className="cart">
          <Link to="/cart">
            <span>Cart ({cartItems.length})</span>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
