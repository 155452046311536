import React, { useState } from 'react';
import Modal from 'react-modal';
import { auth } from './firebase';
import './SignUpModal.css'; // Import the CSS file

Modal.setAppElement('#root');

const SignUpModal = ({ isOpen, onClose, onSignUpSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const maxPasswordLength = 20;

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        onSignUpSuccess(userCredential.user.email);
        onClose();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Sign Up" className="small-modal">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Password:</label>
        <input
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          maxLength={maxPasswordLength}
        />
        <div className="character-count">
          {maxPasswordLength - password.length} characters left
        </div>
        <div className="show-password-container">
          <input
            type="checkbox"
            checked={showPassword}
            onChange={toggleShowPassword}
          />
          <label>Show Password</label>
        </div>
        <button type="submit" className="primary-button">Sign Up</button>
        <button type="button" onClick={onClose} className="close-button">Close</button>
      </form>
      {error && <p className="message">{error}</p>}
    </Modal>
  );
};

export default SignUpModal;
