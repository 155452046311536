// src/IndianCommunities.js
import React from 'react';
import './IndianCommunities.css';
import { Link } from 'react-router-dom';

const IndianCommunities = () => {
  const categories = [
    {
      name: 'Community Links',
      subCategories: [
        { name: 'Gujarati', url: 'http://www.gssatx.org/' },
        { name: 'Bengali', url: 'https://sanantoniobcc.org/' },
        { name: 'Tamil', url: 'https://satamilsangam.org/' },
        { name: 'Telegu', url: 'https://www.tasatexas.org/' },
        { name: 'Odisha', url: 'https://sanantonioodia.org/' },
        { name: 'Marathi', url: '#' },
        { name: 'Kannada', url: 'https://www.kks-sa.org/html/NewSite/about.html' },
        { name: 'Assam', url: 'https://www.facebook.com/p/Assam-Association-of-San-Antonio-100068317202913/' }
      ]
    },
    {
      name: 'Catering',
      subCategories: [
        {
          name: 'Gujarati',
          subLinks: [
            { name: 'BAPS', url: 'https://www.baps.org/Global-Network/North-America/SanAntonio.aspx' }
          ]
        },
        {
          name: 'North Indian',
          subLinks: [
            { name: 'Simi’s', url: 'https://www.facebook.com/p/Simis-India-Cuisine-100064028920187/' },
            { name: 'India Oven', url: 'https://www.indiaovensa.com/' },
            { name: 'India Palace', url: 'https://indiapalace-sa.com/menu' }
          ]
        },
        {
          name: 'South Indian',
          subLinks: [
            { name: 'Spice', url: 'https://www.spicefineindiancuisine.com/' },
            { name: 'Biryani Bowl', url: 'https://www.biryanibowltx.com/' },
            { name: 'Madurai Mes', url: 'https://maduraimes.com/' }
          ]
        }
      ]
    },
    {
      name: 'Groceries',
      subCategories: [
        { name: 'Shahi', url: '/shahi' },
        { name: 'Himalayan Bazaar', url: '#' },
        { name: 'Manpasand', url: '#' }
      ]
    }
  ];

  return (
    <div className="indian-communities">
      <h2>Indian Communities</h2>
      {categories.map((category, index) => (
        <div key={index} className="category-section">
          <h3 className="category-name">{category.name}</h3>
          <div className="sub-community-container">
            {category.subCategories.map((subCategory, subIndex) => (
              <div key={subIndex} className="sub-category">
                <Link to={subCategory.url} className="sub-community-tile">
                  {subCategory.name}
                </Link>
                {subCategory.subLinks && (
                  <div className="sub-sub-community-container">
                    {subCategory.subLinks.map((subSubCategory, subSubIndex) => (
                      <a key={subSubIndex} href={subSubCategory.url} className="sub-sub-community-tile" target="_blank" rel="noopener noreferrer">
                        {subSubCategory.name}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default IndianCommunities;
