import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import 'flag-icons/css/flag-icons.min.css'; // Import flag icons
import NavBar from './NavBar';
import SignUpModal from './SignUpModal';
import SignInModal from './SignInModal';
import { auth } from './firebase'; // Import Firebase auth for sign-out functionality
import IndianCommunities from './IndianCommunities'; // Import the existing component
import MainCategories from './MainCategories'; // Import the new component
import ContactUs from './ContactUs'; // Import the ContactUs component
import AboutUs from './AboutUs'; // Import the AboutUs component
import ThankYou from './ThankYou'; // Import the ThankYou component
import Shahi from './Shahi'; // Import the Shahi component



function App() {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [cartItems, setCartItems] = useState([]);

  const links = [
    { name: 'Indian', url: '/indian-communities', flag: 'in' },
    { name: 'British', url: '#', flag: 'gb' },
    { name: 'Chinese', url: '#', flag: 'cn' },
    { name: 'Eritrean', url: '#', flag: 'er' },
    { name: 'Ethiopian', url: '#', flag: 'et' },
    { name: 'Greek', url: '#', flag: 'gr' },
    { name: 'Hawaiian', url: '#', flag: 'us' }, // Hawaiian flag is the US flag
    { name: 'Jamaican', url: '#', flag: 'jm' },
    { name: 'Lebanese', url: '#', flag: 'lb' },
    { name: 'Nepalese', url: '#', flag: 'np' },
    { name: 'Nigerian', url: '#', flag: 'ng' },
    { name: 'Russian', url: '#', flag: 'ru' },
    { name: 'Syrian', url: '#', flag: 'sy' },
    { name: 'Thai', url: '#', flag: 'th' },
    { name: 'Trinidadian', url: '#', flag: 'tt' },
    { name: 'Turkish', url: '#', flag: 'tr' }
  ];

  const handleSignUpClick = () => {
    setIsSignUpOpen(true);
  };

  const handleSignUpClose = () => {
    setIsSignUpOpen(false);
  };

  const handleSignInClick = () => {
    setIsSignInOpen(true);
  };

  const handleSignInClose = () => {
    setIsSignInOpen(false);
  };

  const handleSignUpSuccess = (email) => {
    setUsername(email);
  };

  const handleSignInSuccess = (email) => {
    setUsername(email);
  };

  const handleSignOut = () => {
    auth.signOut().then(() => {
      setUsername(null);
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleAddToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  const filteredLinks = links.filter(link =>
    link.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Router>
      <div className="App">
        <NavBar 
          onSignInClick={handleSignInClick} 
          onSignUpClick={handleSignUpClick} 
          onSignOutClick={handleSignOut}
          username={username}
          onSearch={handleSearch}
          cartItems={cartItems} // Pass cart items to NavBar
        />
        <header className="App-header">
          <div className="navbar-left">
          </div>
        </header>
        <div className="container">
          <div className="links-section">
            <h4>Hub</h4>
            <div className="scrolling-links">
              <ul className="links-list">
                {filteredLinks.map((link, index) => (
                  <li key={index}>
                    <Link to={link.url}>
                      <span className={`fi fi-${link.flag} flag-icon`}></span>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="main-content">
            <Routes>
              <Route exact path="/" element={<MainCategories />} />
              <Route path="/indian-communities" element={<IndianCommunities />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/shahi" element={<Shahi onAddToCart={handleAddToCart} />} /> {/* Pass the handler to Shahi component */}
            </Routes>
          </div>
        </div>
        <SignUpModal isOpen={isSignUpOpen} onClose={handleSignUpClose} onSignUpSuccess={handleSignUpSuccess} />
        <SignInModal isOpen={isSignInOpen} onClose={handleSignInClose} onSignInSuccess={handleSignInSuccess} />
      </div>
    </Router>
  );
}

export default App;
