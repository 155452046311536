// src/AboutUs.js
import React from 'react';

function AboutUs() {
  return (
    <div>
      <h2>About Us</h2>
      <p>This is the About Us page.</p>
    </div>
  );
}

export default AboutUs;
