import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './ContactUs.css';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectCharsLeft, setSubjectCharsLeft] = useState(50);
  const [messageCharsLeft, setMessageCharsLeft] = useState(200);
  const [attachments, setAttachments] = useState([null, null, null, null, null]);

  const formRef = useRef(null);
  const fileInputRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]);
  const navigate = useNavigate();

  const handleAttachmentChange = (e, index) => {
    const files = [...attachments];
    files[index] = e.target.files[0];
    setAttachments(files);
  };

  const handleAttachmentRemove = (index) => {
    const files = [...attachments];
    files[index] = null;
    setAttachments(files);
    if (fileInputRefs.current[index].current) {
      fileInputRefs.current[index].current.value = ''; // Clear the file input value
    }
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setSubjectCharsLeft(50 - e.target.value.length);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setMessageCharsLeft(200 - e.target.value.length);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_wcb9p0d', // Your service ID
      'template_ihozmv8', // Your template ID
      formRef.current, // Form reference
      '4L6c5XHTR-ND4i4-d' // Your user ID
    )
    .then((result) => {
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setAttachments([null, null, null, null, null]);
      navigate('/thank-you'); // Navigate to the thank you page
    }, (error) => {
      console.error('EmailJS error:', error); // Log error details
      alert('An error occurred, please try again.');
    });
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <form className="contact-form" ref={formRef} onSubmit={handleSubmit}>
        <input
          type="text"
          name="from_name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          name="reply_to"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          value={subject}
          onChange={handleSubjectChange}
          maxLength={50}
          required
        />
        <div className="char-count">{subjectCharsLeft} characters left</div>
        <textarea
          name="message"
          placeholder="Message"
          value={message}
          onChange={handleMessageChange}
          maxLength={200}
          required
        />
        <div className="char-count">{messageCharsLeft} characters left</div>
        {attachments.map((attachment, index) => (
          <div key={index} className="attachment-container">
            <label>{`Attachment ${index + 1}`}</label>
            <input
              type="file"
              name={`attachment${index + 1}`}
              onChange={(e) => handleAttachmentChange(e, index)}
              ref={fileInputRefs.current[index]}
            />
            {attachment && (
              <span className="file-name">
                {attachment.name.length > 10 ? `${attachment.name.substring(0, 10)}...` : attachment.name}
              </span>
            )}
            {attachment && (
              <button type="button" className="cancel-button" onClick={() => handleAttachmentRemove(index)}>
                Cancel
              </button>
            )}
          </div>
        ))}
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUs;
