// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDjHMeDcRXcON5uPsTkCgV-aMo6U5MQekI",
  authDomain: "goourhub-23c7d.firebaseapp.com",
  projectId: "goourhub-23c7d",
  storageBucket: "goourhub-23c7d.appspot.com",
  messagingSenderId: "296561163593",
  appId: "1:296561163593:web:a84c47fafc4daf6a446e98"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
