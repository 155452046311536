import React from 'react';
import './MainCategories.css';

const MainCategories = () => {
  const productCategories = [
    { name: 'Accommodation', subCategories: ['Hotel', 'Motel', 'Guest House'] },
    { name: 'Boutique', subCategories: ['Clothing', 'Accessories', 'Handmade'] },
    { name: 'Construction', subCategories: ['Residential', 'Commercial', 'Renovation'] },
    { name: 'Home Help', subCategories: ['Cleaning', 'Cooking', 'Babysitting'] },
    { name: 'Jewelry', subCategories: ['Gold', 'Silver', 'Diamond'] },
    { name: 'Food Truck', subCategories: ['Mexican', 'Italian', 'Indian'] },
    { name: 'Restaurants', subCategories: ['Fast Food', 'Fine Dining', 'Casual Dining'] }
  ];

  const serviceCategories = [
    { name: 'Realtor', subCategories: ['Buying', 'Selling', 'Renting'] },
    { name: 'Transportation', subCategories: ['Taxi', 'Bus', 'Train'] },
    { name: 'Legal', subCategories: ['Corporate', 'Criminal', 'Civil'] },
    { name: 'Education', subCategories: ['Schools', 'Colleges', 'Universities'] },
    { name: 'Health', subCategories: ['Hospitals', 'Clinics', 'Pharmacies'] },
    { name: 'Student Services', subCategories: ['Counseling', 'Tutoring', 'Mentoring'] },
    { name: 'Beauty', subCategories: ['Salons', 'Spas', 'Nail Studios'] },
    { name: 'Taylor', subCategories: ['Men', 'Women', 'Kids'] },
    { name: 'Storage', subCategories: ['Personal', 'Business', 'Vehicle'] },
    { name: 'Translation', subCategories: ['Documents', 'Interpretation', 'Localization'] },
    { name: 'Venues', subCategories: ['Wedding', 'Conference', 'Party'] },
    { name: 'Worship Places', subCategories: ['Church', 'Temple', 'Mosque'] },
    { name: 'Entertainment', subCategories: ['Movies', 'Concerts', 'Theater'] },
    { name: 'Employment', subCategories: ['Full-Time', 'Part-Time', 'Freelance'] },
    { name: 'Financial', subCategories: ['Banking', 'Insurance', 'Investment'] },
    { name: 'Communication', subCategories: ['Phone', 'Internet', 'Mail'] },
    { name: 'Sports', subCategories: ['Football', 'Basketball', 'Tennis'] }
  ];

  return (
    <div className="main-categories-wrapper">
      <div className="main-categories-container">
        <div className="big-category-tile">
          <h2 className="product-service-header">Products</h2>
          <div className="product-service-categories">
            {productCategories.map((category, index) => (
              <div key={index} className="category">
                <span className="category-name">{category.name}</span>
                <div className="types">
                  {category.subCategories.map((subCategory, subIndex) => (
                    <div key={subIndex} className="type">
                      {subCategory}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="big-category-tile">
          <h2 className="product-service-header">Services</h2>
          <div className="product-service-categories">
            {serviceCategories.map((category, index) => (
              <div key={index} className="category">
                <span className="category-name">{category.name}</span>
                <div className="types">
                  {category.subCategories.map((subCategory, subIndex) => (
                    <div key={subIndex} className="type">
                      {subCategory}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="ad-section">
        <h2>Want to sell your Goods & Services?</h2>
        <p>You are at the Right Place! At our platform, you can buy or sell Goods & Services to the user via posting an AD.</p>
        <button className="explore-button">Explore</button>
        <button className="post-ad-button">Post an Ad</button>
      </div>
    </div>
  );
};

export default MainCategories;
